h1, h2, h3, h4, h5, h6 {
  color: #221d31;
}

.navbar {
  margin-top: 0;
  height: 40px;
  padding: 10px;
}

.navbar-logo {
  float: left;
  width: auto;
  margin-top: 5px;
}
.navbar-list {
  list-style: none;
  margin-bottom: 0;
}

.about .about-link {
  color: #221d31;

}

.portfolio .main-link {
  color: #221d31;

}
.navbar-item {
  position: relative;
  float: right;
  margin-bottom: 0;
  list-style: none;
  margin-left: 20px;
}
.navbar-item a{
  font-size: 1.2em;
  color: #8d8897;
  font-weight: 400;
  letter-spacing: 0.05em;
}
.about-me {
  margin-top: 10%;
}
.about-me h1{
  font-size: 2.5em;
}
.mo-intro {

  min-height: 250px;
  padding-top: 10%;
  padding-bottom: 0;
}

.about-intro-text {
  font-size: 1.5em;
  font-weight: 400;
}

.mo-intro h1 {
  text-align: center;
  font-size: 2.6em;
  font-weight: 200;
}
.portfolio-item {
  margin: 50px auto;
  border-radius: 25px;
  border: 1px solid #e8e5ee;
  min-height: 320px;
}

.portfolio-item-title {
  margin-top: 4%;
  font-size: 2em;
  font-weight: 500;
  color: #3e3655;
}
.portfolio-item-text {
  font-size: 1.05em;
  font-weight: 400;
  padding-right: 5%;
  margin-top: 6%;
  color: #514b5e;
}

.portfolio-tags-lists li{
  display: inline-block;
  margin-right: 15px;
  font-size: 1em;
  color: #c2bccf;
  font-weight: 400;
  letter-spacing: 0.06em;
  
}
.portfolio-tags-lists li span img{
  vertical-align: middle;
  margin-bottom: 3px;
}

.portfolio-item-link {
  margin: 10% 8% 0 0;
  text-align: right;
}
.portfolio-item-link a{
  color: #413b66;
  font-size: 1.1em;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #f2efff;
  transition: all 90ms;
  
}
.portfolio-item-link a:hover{
  background-color: #efebff;
}

.portfolio-tumb{
min-height: 320px;
border-radius: 25px 0 0 25px;
}

.portfolio-tumb.molo-tumb{
background: url('./images/molo-tumb.svg') no-repeat center center  #87CBCF;
width: 100%;
}
.portfolio-tumb.collectAI-tumb{
background: url('./images/collectAI-tumb.svg') no-repeat center center  #cbe3f8;
width: 100%;
}
.portfolio-tumb.foodle-tumb{
background: url('./images/foodle-tumb.svg') no-repeat center center  #fcc3ce;
width: 100%;
}

.portfolio-cover{
height: 470px;
padding-top: 30px;

}
.portfolio-cover.molonews-cover{
  background: url('./images/molo-cover.png') no-repeat center bottom #87CBCF;
}
.portfolio-cover.collectAI-cover{
  height: 540px;
  background: url('./images/collectAI-cover.png') no-repeat center bottom #cbe3f8;
}
.portfolio-cover.foodle-cover{
  background: url('./images/foodle-cover.png') no-repeat center bottom #fcc3ce;
}

.portfolio-section-text {
  font-size: 1.1em;
  padding-right: 10px;
}

.portfolio-section-img {
  padding-right: 10px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}


h4.portfolio-section-head {
  margin-top: 1.5em;
  font-weight: 500;
  color: #4b516b;
  font-size: 1.3em;
  letter-spacing: 0.03em;
}

h5.portfolio-section-head {
  margin-top: 0.8em;
  font-weight: 400;
}

.data-note{
  text-align: center;
  margin-top: 2%;
  color: #b4b5c2;
}
.data-note span{ 
font-weight: 800;
font-size: 20px;
color: #e48fa0;
}
.img-gif {
  margin-right: 10px;

}

.footer {
  text-align: center;
  padding: 50px 0;
}
.footer h5{
  margin: 30px auto;
}

.footer-links li{
  display: inline-block;
  margin: 0 30px;
}

.footer-links li a{
  color: #221d31;
  font-weight: 500;
}


@media screen and (max-width: 768px) {
  .portfolio-section-image img{
    width: 100% !important;
  }
  .portfolio-cover {
    background-size: contain !important;
  }
  .portfolio-tumb {
    background-size: contain !important;
  
  }
  
}
@media screen and (max-width: 959px) {
  .portfolio-section-image img {
    width: 100% !important;

  }

}
@media screen and (max-width: 548px) {
  .portfolio-tumb {
    border-radius: 25px 25px 0 0 ;
  }
  .portfolio-item-title, .portfolio-item-text,  .portfolio-tags-lists{
    margin-left: 10px;
  }
  .portfolio-item-link {
    margin: 20px;
  }
  .footer-links li{
    display: block;
    margin: 10px auto
  
  }
  .about-me .portfolio-section-image img {
    width: 50% !important;

  }
  .about-me .portfolio-section-image  {
    
    text-align: center;
  }
}